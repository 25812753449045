import React from "react";
import AgriProduceMainBanner from "../templates/OurSegments/AgriProduce/AgriProduceMainBanner";
import AboutAgriProduce from "../templates/OurSegments/AgriProduce/AboutAgriProduce";
import AgriProduceGallery from "../templates/OurSegments/AgriProduce/AgriProduceGallery";
import Rice from "../templates/OurSegments/AgriProduce/AgriProduceRice";
import Diary from "../templates/OurSegments/AgriProduce/AgriProduceDiary";
import FreshProduce from "../templates/OurSegments/AgriProduce/AgriProduceFreshProduce";

function AgriProduce() {
  return (
    <div>
      <AgriProduceMainBanner />
      <AboutAgriProduce />
      <Rice />
      <Diary />
      <FreshProduce />
      <AgriProduceGallery />
    </div>
  );
}

export default AgriProduce;
