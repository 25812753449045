import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./AgriProduceRice.css";
import IconChevronright from "../../../images/vector-6.svg";
import PlaceholderImage from "../../../images/AgriProduceRice.png";

export default function Rice() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="rice-layout-192">
        <div className="rice-container2">
          <img
            data-aos="fade-right"
            data-aos-duration="500"
            className="rice-placeholder-image-icon"
            alt=""
            src={PlaceholderImage}
          />
          <div className="rice-content3">
            <div className="rice-section-title">
              <div className="rice-content4">
                <div
                  className="rice-heading"
                  data-aos="fade-left"
                  data-aos-duration="500"
                >
                  Rice
                </div>
                <div
                  className="rice-text1"
                  data-aos="fade-left"
                  data-aos-duration="500"
                >
                  The “CIC Golden Crop" label is not only one of the most
                  recognizable Rice brands in Sri Lanka, but also the only brand
                  to offer a unique range of specialty rice varieties, including
                  nutritionally enriched Health Rice, authentic Sri Lankan
                  Traditional Rice and highly palatable Specialty Rice. The
                  result of extensive in-house research, these novel
                  formulations all contain authentic flavour and texture
                  profiles as well as their own unique health and nutritional
                  benefits. Our most recent innovation - the Purple Queen rice
                  is currently in the process of being market tested both in Sri
                  lanka and overseas.
                </div>
              </div>
            </div>
            <div
              className="rice-actions2"
              data-aos="fade-left"
              data-aos-duration="700"
            >
              <a href="/quality-rice/" className="rice-button3">
                <div className="rice-button-child" />
                <div className="rice-read-more">Read More</div>
                <img
                  className="rice-button-item"
                  alt=""
                  src={IconChevronright}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
