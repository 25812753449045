import React, { useEffect } from "react";
import "./AgriProduceDiary.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Image1 from "../../../images/Diary.webp";
import CropSegVector from "../../../images/vector-61.svg";

export default function SegmentCrop() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="diary-layout-4">
        <div className="diary-container3">
          <div className="diary-content5">
            <div className="diary-content6">
              <div className="diary-section-title">
                <div className="diary-content4">
                  <div
                    className="diary-heading"
                    data-aos="fade-right"
                    data-aos-duration="500"
                  >
                    Diary
                  </div>
                  <div
                    className="diary-text1"
                    data-aos="fade-right"
                    data-aos-duration="500"
                  >
                    Our dairy range comprising cultured dairy and fresh UHT milk
                    is marketed under the iconic CIC Creamoo label which holds a
                    commanding position in these respective market spaces. CIC
                    Creamoo curd, yoghurt and fresh milk are all produced at our
                    state-of-the-art, internationally accredited plant in
                    Dambulla which has the capacity to process 14,000 litres of
                    raw milk per day.
                  </div>
                </div>
              </div>
            </div>
            <div
              className="diary-actions2"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              <a href="/diary-products/" className="diary-button3">
                <div className="diary-button-child" />
                <div className="diary-read-more">Read More</div>
                <img className="diary-button-item" alt="" src={CropSegVector} />
              </a>
            </div>
          </div>
          <img
            data-aos="fade-left"
            data-aos-duration="500"
            className="diary-placeholder-image-icon"
            alt=""
            src={Image1}
          />
        </div>
      </div>
    </div>
  );
}
