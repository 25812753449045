import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./AboutAgriProduce.css";

export default function AboutAgriProduce() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="agriProduce-layout-42">
        <div className="agriProduce-content2">
          <div
            className="agriProduce-column2"
            data-aos="fade-right"
            data-aos-duration="700"
          >
            <div className="agriProduce-heading">Agri Produce</div>
          </div>
          <div className="agriProduce-column3">
            <div className="agriProduce-text">
              <p
                className="agriProduce-this-was-done"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                CIC is the only company in Sri Lanka that manages the entire
                supply chain from seed to shelf connecting rural farmers to
                urban consumers and facilitating the socio economic progress of
                rural communities with the guarantee of CIC quality.
              </p>
              <p className="agriProduce-this-was-done">&nbsp;</p>
              <p
                className="agriProduce-this-was-done"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                CIC Agribusiness manages over 10,000 acres of farmland and work
                with over 20,000 rural farmers in producing a wide range of Agri
                Produce including Healthy Rice, Fruits, Vegetables, Seeds and
                grains and Dairy products which caters to the country’s food
                security, nutrition and import substitution initiatives. CIC
                uses internationally-accepted food safety standards and good
                agricultural practices, that create produce that contains the
                wholesome goodness and nutritional value required for
                nourishment. We also make substantial investments in our
                research and development facilities which include rice breeding,
                soil labs, seed labs, food labs and tissue culture labs and work
                with reputed overseas principals for developing modern
                technologies comprising high yielding and pest resistant crop
                varieties into the country.
              </p>
              <p className="agriProduce-this-was-done">&nbsp;</p>
              <p
                className="agriProduce-this-was-done"
                data-aos="fade-left"
                data-aos-duration="700"
              >
                In addition, its own crop and livestock farms act as technology
                transfer centers to the farming community of Sri Lanka
                introducing new agricultural technologies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
