import React, { useState, useEffect } from "react";
import "./AgriProduceGallery.css";
import InvestorWhyImage1 from "../../../images/1-APRC-9.jpg";
import InvestorWhyImage2 from "../../../images/2-Holiday-Resort-12.jpg";
import InvestorWhyImage3 from "../../../images/3-Dairy-4.jpg";
import InvestorWhyImage4 from "../../../images/5-Farms-12-2.jpg";
import InvestorWhyImage5 from "../../../images/5-Farms-14-2.jpg";
import InvestorWhyImage6 from "../../../images/5-Fruits-Vegetables-8-300x200.jpg";
import InvestorWhyImage7 from "../../../images/8-Rice-10-300x200.jpg";
import InvestorWhyImage8 from "../../../images/9-Seeds-11.jpg";

const slideWidth = 30;

const _items = [
  {
    player: {
      image: InvestorWhyImage1,
    },
  },
  {
    player: {
      image: InvestorWhyImage2,
    },
  },
  {
    player: {
      image: InvestorWhyImage3,
    },
  },
  {
    player: {
      image: InvestorWhyImage4,
    },
  },
  {
    player: {
      image: InvestorWhyImage5,
    },
  },
  {
    player: {
      image: InvestorWhyImage6,
    },
  },
  {
    player: {
      image: InvestorWhyImage7,
    },
  },
  {
    player: {
      image: InvestorWhyImage8,
    },
  },
];

const length = _items.length;
const infiniteItems = [..._items, ..._items];

const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const createItem = (position, idx) => {
  return {
    styles: {
      transform: `translateX(${position * slideWidth}rem)`,
    },
    player: infiniteItems[idx].player,
  };
};

const CarouselSlideItem = ({ pos, idx }) => {
  const item = createItem(pos, idx);

  return (
    <li className="AgriProduceGallery__slide-item" style={item.styles}>
      <div className="AgriProduceGallery__slide-item-img-link">
        <img src={item.player.image} />
      </div>
    </li>
  );
};

const AgriProduceGallery = () => {
  const [items, setItems] = useState(
    Array.from(Array(infiniteItems.length).keys())
  );
  const [isTicking, setIsTicking] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const bigLength = items.length;

  const prevClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => prev.map((_, i) => prev[(i + jump) % bigLength]));
    }
  };

  const nextClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) =>
        prev.map((_, i) => prev[(i - jump + bigLength) % bigLength])
      );
    }
  };

  const handleDotClick = (idx) => {
    if (idx < activeIdx) prevClick(activeIdx - idx);
    if (idx > activeIdx) nextClick(idx - activeIdx);
  };

  useEffect(() => {
    if (isTicking) sleep(300).then(() => setIsTicking(false));
  }, [isTicking]);

  useEffect(() => {
    setActiveIdx((length - (items[0] % length)) % length);
  }, [items]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextClick();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="AgriProduceGallery-slider">
      <h1
        className="AgriProduceGallery-title"
        data-aos="fade-down"
        data-aos-duration="750"
      >
        Gallery
      </h1>
      <div className="AgriProduceGallery__wrap">
        <div className="AgriProduceGallery__inner">
          <button
            className="AgriProduceGallery__btn AgriProduceGallery__btn--prev"
            onClick={() => prevClick()}
          >
            <i className="AgriProduceGallery__btn-arrow AgriProduceGallery__btn-arrow--left" />
          </button>
          <div className="AgriProduceGallery__container">
            <ul className="AgriProduceGallery-ul AgriProduceGallery__slide-list">
              {items.map((pos, i) => (
                <CarouselSlideItem key={i} idx={i} pos={pos} />
              ))}
            </ul>
          </div>
          <button
            className="AgriProduceGallery__btn AgriProduceGallery__btn--next"
            onClick={() => nextClick()}
          >
            <i className="AgriProduceGallery__btn-arrow AgriProduceGallery__btn-arrow--right" />
          </button>
          <div className="AgriProduceGallery__dots">
            {items.slice(0, length).map((pos, i) => (
              <button
                key={i}
                onClick={() => handleDotClick(i)}
                className={
                  i === activeIdx
                    ? "AgriProduceGallery-dot active"
                    : "AgriProduceGallery-dot"
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgriProduceGallery;
