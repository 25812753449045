import React from "react";
import "./AgriProduceMainBanner.css";
import AgriImage from "../../../images/SegmentAgriImage.png";

export default function AgriProduceMainBanner() {
  return (
    <div>
      <div>
        <div className="AgriProduceMain">
          <img className="AgriProduceMain-home-image" alt="" src={AgriImage} />
          <div className="AgriProduceMain-image-text-box">
            <div className="AgriProduceMain-image-background-text">
              <h1 className="AgriProduceMain-image-topic">Agri Produce</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
