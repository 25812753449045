import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./AgriProduceFreshProduce.css";
import IconChevronright from "../../../images/vector-6.svg";
import PlaceholderImage from "../../../images/FreshProduce.png";

export default function Rice() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="fresh-produce-layout-192">
        <div className="fresh-produce-container2">
          <img
            data-aos="fade-right"
            data-aos-duration="500"
            className="fresh-produce-placeholder-image-icon"
            alt=""
            src={PlaceholderImage}
          />
          <div className="fresh-produce-content3">
            <div className="fresh-produce-section-title">
              <div className="fresh-produce-content4">
                <div
                  className="fresh-produce-heading"
                  data-aos="fade-left"
                  data-aos-duration="500"
                >
                  Fresh Produce
                </div>
                <div
                  className="fresh-produce-text1"
                  data-aos="fade-left"
                  data-aos-duration="500"
                >
                  CIC’s Fresheez Outlet has since opening the first outlet have
                  single handedly transformed the way Sri Lankan consumers
                  perceive fresh produce. Since the inception, the Fresheez
                  brand has differentiated itself through its commitment to
                  quality and consistency, which has earned the brand a loyal
                  following.
                </div>
              </div>
            </div>
            <div
              className="fresh-produce-actions2"
              data-aos="fresh-produce-left"
              data-aos-duration="700"
            >
              <a href="/fresh-produce/" className="fresh-produce-button3">
                <div className="fresh-produce-button-child" />
                <div className="fresh-produce-read-more">Read More</div>
                <img
                  className="fresh-produce-button-item"
                  alt=""
                  src={IconChevronright}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
